export const all_routes = {
  signin: "/signin",
  home:"/",
  error404: "/error-404",
  error500: "/error-500",
  forgotPassword: "/forgot-password",
  resetpassword: "/reset-password",
  emailverification: "/email-verification",
  dashboard: "/admin-dashboard",
  pos: "/pos",
  addproduct: "/add-product",
  productlist: "/product-list",
  lowstock: "/low-stocks",
  categorylist: "/category-list",
  subcategories: "/sub-categories",
  salesreport: "/sales-report",
  purchasereport: "/purchase-report",
  profitloss: "/profit-loss-report",
  customers: "/customers",
  users: "/users",
  rolespermission: "/roles-permissions",
};
